<template>
    <div>
        <!-- 區塊一 -->
        <div>
            <b-container class="bv-example-row py-2">
                <b-row class="my-3 justify-content-center">
                    <b-col class="mb-2 loan-type-col">
                        <b-button variant="warning" size="lg" @click="changeLoanType(0)"
                                  class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">汽機車借貸
                        </b-button>
                    </b-col>

                    <b-col class="mb-2 loan-type-col">
                        <b-button variant="warning" size="lg" @click="changeLoanType(1)"
                                  class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-yellow">房屋貸款
                        </b-button>
                    </b-col>

                    <b-col class="mb-2 loan-type-col">
                        <b-button variant="warning" size="lg" @click="changeLoanType(2)"
                                  class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">信用貸款
                        </b-button>
                    </b-col>

                    <b-col class="mb-2 loan-type-col">
                        <b-button variant="warning" size="lg" @click="changeLoanType(3)"
                                  class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-yellow">商品貸款
                        </b-button>
                    </b-col>

                    <b-col class="mb-2 loan-type-col">
                        <b-button variant="warning" size="lg" @click="changeLoanType(4)"
                                  class="text-white font-weight-bold border-0 rounded-0 w-100 bg-sukudai-green">企業貸款
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="my-3 py-3 bg-sukudai-green">
                    <!-- 區塊一 左側圖片輪播 -->
                    <b-col cols="12" lg="7" class="justify-content-center align-items-center">
                        <ImageCarousel carousel-name="Features"/>
                    </b-col>

                    <!-- 區塊一 右側輸入匡 -->
                    <b-col cols="12" lg="5">
                        <div class="bg-sukudai-light-grey p-2">
                            <quick-submit id="quick_submit"/>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 不同貸款別畫面擺放區塊 -->
        <div class="py-3">
            <b-container class="bv-example-row">
                <b-row>
                    <b-col cols="12" lg="6">
                        <!-- 汽機車貸款    -->
                        <img v-if="select_loan === 0" src="../assets/img/car.jpg" style="width: 100%; max-height: 300px"
                             alt="貸款細節示意圖"/>

                        <!-- 房屋貸款    -->
                        <img v-if="select_loan === 1" src="../assets/img/house.jpg"
                             style="width: 100%; max-height: 300px" alt="貸款細節示意圖"/>

                        <!-- 信用貸款    -->
                        <img v-if="select_loan === 2" src="../assets/img/shopping.jpg"
                             style="width: 100%; max-height: 300px" alt="貸款細節示意圖"/>

                        <!-- 商品貸款  -->
                        <img v-if="select_loan === 3" src="../assets/img/creditcard.jpg"
                             style="width: 100%; max-height: 300px" alt="貸款細節示意圖"/>

                        <img v-if="select_loan === 4" src="../assets/img/businessloan.png"
                             style="width: 100%; max-height: 300px" alt="貸款細節示意圖"/>
                    </b-col>
                    <b-col cols="12" lg="6" class="my-2 my-lg-0">
                        <!-- 汽機車貸款    -->
                        <div v-if="select_loan === 0">
                            <div class="px-3 py-1 mb-2 ml-4 bg-sukudai-green font-weight-bold font-md text-white"
                                 style="width: fit-content">汽機車借貸
                            </div>
                            <div class="pl-3 ml-3 border-left border-dark">
                                <span class="my-1 font-md font-weight-bold">誰需要找我們申請汽機車貸款？</span>
                                <ol class="pl-4 my-1">
                                    <li class="mb-2 font-sm">沒有收入證明，信用貸款不過。</li>
                                    <li class="mb-2 font-sm">想買新車但是不想受限於車商配合的方案。</li>
                                    <li class="mb-2 font-sm">貸款遲繳好幾個月，需要現金週轉。</li>
                                    <li class="mb-2 font-sm">卡債積欠已久，循環利息讓債務越滾越大。</li>
                                    <li class="mb-2 font-sm">需要裝修房屋，但房貸貸不過，審核速度慢。</li>
                                    <li class="mb-2 font-sm">投資/進修/結婚/生涯規劃需要資金後盾。</li>
                                </ol>
                            </div>
                        </div>

                        <!-- 房屋貸款    -->
                        <div v-if="select_loan === 1">
                            <div class="px-3 py-1 mb-2 ml-4 bg-sukudai-yellow font-weight-bold font-md text-white"
                                 style="width: fit-content">房屋貸款
                            </div>
                            <div class="pl-3 ml-3 border-left border-dark">
                                <span class="my-1 font-md font-weight-bold">誰需要找我們申請房屋貸款？</span>
                                <ol class="pl-4 my-1">
                                    <li class="mb-2 font-sm">想買房但不確定其款準備夠不夠。</li>
                                    <li class="mb-2 font-sm">房貸方案眾多；不知如何選擇。</li>
                                    <li class="mb-2 font-sm">需要大筆資金；但擔心造成還款負擔。</li>
                                    <li class="mb-2 font-sm">有信用瑕疵紀錄，申貸困難重重。</li>
                                    <li class="mb-2 font-sm">房屋已有貸款，想要知道增貸或轉貸的可能性。</li>
                                    <li class="mb-2 font-sm">不確定持有的房屋或土地能不能申請貸款做資金週轉用途。</li>
                                </ol>
                            </div>
                        </div>

                        <!-- 信用貸款    -->
                        <div v-if="select_loan === 2">
                            <div class="px-3 py-1 mb-2 ml-4 bg-sukudai-green font-weight-bold font-md text-white"
                                 style="width: fit-content">信用貸款
                            </div>
                            <div class="pl-3 ml-3 border-left border-dark">
                                <span class="my-1 font-md font-weight-bold">誰適合找我們申請信用貸款？</span>
                                <ol class="pl-4 my-1">
                                    <li class="mb-2 font-sm">沒有貸款經驗，不知從何下手。</li>
                                    <li class="mb-2 font-sm">自己跑銀行申貸，但屢屢遭受退件。</li>
                                    <li class="mb-2 font-sm">與銀行不曾往來，信用一片空白。</li>
                                    <li class="mb-2 font-sm">各家銀行方案眾多，不知如何選擇。</li>
                                    <li class="mb-2 font-sm">銀行核貸金額總是不符合理想。</li>
                                    <li class="mb-2 font-sm">平時工作忙碌；沒時間四處奔波。</li>
                                </ol>
                            </div>
                        </div>
                        <!-- 商品貸款  -->
                        <div v-if="select_loan === 3">
                            <div class="px-3 py-1 mb-2 ml-4 bg-sukudai-yellow font-weight-bold font-md text-white"
                                 style="width: fit-content">商品貸款
                            </div>
                            <div class="pl-3 ml-3 border-left border-dark">
                                <span class="my-1 font-md font-weight-bold">誰適合找我們申請商品貸款？</span>
                                <ol class="pl-4 my-1">
                                    <li class="mb-2 font-sm">負債比過高，超過月收入22倍。</li>
                                    <li class="mb-2 font-sm">申貸條件不足，遭銀行退件。</li>
                                    <li class="mb-2 font-sm">個人信用瑕疵，被銀行拒於門外。</li>
                                    <li class="mb-2 font-sm">存款不足退票，銀行列為拒絕往來戶。</li>
                                    <li class="mb-2 font-sm">卡債過高/強制停卡/預借現金/信用分數低。</li>
                                    <li class="mb-2 font-sm">有貸款遲繳；呆帳未清紀錄。</li>
                                </ol>
                            </div>
                        </div>

                        <!-- 企業貸款  -->
                        <div v-if="select_loan === 4">
                            <div class="px-3 py-1 mb-2 ml-4 bg-sukudai-green font-weight-bold font-md text-white"
                                 style="width: fit-content">企業貸款
                            </div>
                            <div class="pl-3 ml-3 border-left border-dark">
                                <span class="my-1 font-md font-weight-bold">什麼樣的企業戶需動用企業貸款</span>
                                <ol class="pl-4 my-1">
                                    <li class="mb-2 font-sm">1. 公司擴展修繕</li>
                                    <li class="mb-2 font-sm">2. 購買新產房</li>
                                    <li class="mb-2 font-sm">3. 公司應收帳款期數過長，導致週轉不靈...等正當用途</li>
                                </ol>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 下方四格方塊圖片 -->
        <div class="bg-sukudai-green py-4">
            <b-container class="bv-example-row">
                <b-row>
                    <b-col cols="12" md="6" lg="3" class="mb-2 mb-lg-0">
                        <div class="bg-white p-2">
                            <!-- 汽機車貸款    -->
                            <div v-if="select_loan === 0">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">所需準備文件</p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">1</span>
                                    車主身分證反面影本
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">2</span>
                                    行車執照影本
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">3</span>
                                    銀行或郵局存摺封面影本
                                </p>
                            </div>

                            <!-- 房屋貸款  -->
                            <div v-if="select_loan === 1">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">服務特色</p>
                                <p class="font-weight-bold" style="line-height: 2em">
                                    利用房屋抵押取得資金，容易貸到較高額度與較低利率，信用狀況不佳亦可申辦。</p>
                            </div>


                            <!-- 信用貸款    -->
                            <div v-if="select_loan === 2">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">服務特色</p>
                                <p class="row">
                                <span class="col-3 text-center">
                                    <img src="../assets/icon/credit/2.png" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-9 font-weight-bold">一般上班族；工作滿3個月有固定收入者</span>
                                </p>
                                <p class="row">
                                <span class="col-3 text-center">
                                    <img src="../assets/icon/credit/1.png" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-9 font-weight-bold">開業滿6個月之自營商及企業負責人</span>
                                </p>
                            </div>

                            <!-- 商品貸款  -->
                            <div v-if="select_loan === 3">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">所需準備文件</p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">1</span>
                                    身分證 + 第二證件(影本)
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">2</span>
                                    勞保證明 + 薪轉存褶
                                </p>
                            </div>

                            <!-- 企業貸款  -->
                            <div v-if="select_loan === 4">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">企業貸款種類</p>
                                <p class="font-weight-bolder font-xl text-center">有擔保品<br/>和<br/>無擔保品</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="mb-2 mb-lg-0">
                        <div class="bg-white p-2">
                            <!-- 汽機車貸款    -->
                            <div v-if="select_loan === 0">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款期限</p>
                                <p class="font-weight-bolder font-xxl text-center">最長<span
                                    style="font-size: 1.5em; line-height: .8em">6</span>年</p>
                                <p class="font-weight-bold text-center">可按月本金加利息攤還</p>
                            </div>
                            <!-- 房屋貸款  -->
                            <div v-if="select_loan === 1">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款期限</p>
                                <p class="font-weight-bolder font-xxl text-center">最長<span
                                    style="font-size: 1.5em; line-height: .8em">30</span>年</p>
                                <p class="font-weight-bold text-center">房屋貸款最長可分期30年，綁約1~3年。</p>
                            </div>
                            <!-- 信用貸款    -->
                            <div v-if="select_loan === 2">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款期限</p>
                                <p class="font-weight-bolder font-xxl text-center">最長<span
                                    style="font-size: 1.5em; line-height: .8em">7</span>年</p>
                                <p class="font-weight-bold text-center">信用貸款期限最短1年；最長可達7年</p>
                            </div>
                            <!-- 商品貸款  -->
                            <div v-if="select_loan === 3">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">服務特色</p>
                                <p class="font-weight-bold" style="line-height: 2em">
                                    提供因信用瑕疵無法向銀行貸款的貸款人，透過民間借貸取得資金的管道(絕對合法)，可以貸償錢莊與當鋪等高等利息貸款。</p>
                            </div>

                            <!-- 企業貸款 -->
                            <div v-if="select_loan === 4">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">基本條件</p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">1</span>
                                    負責人年滿20歲
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">2</span>
                                    公司成立領有營業事業登記證
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="mb-2 mb-lg-0">
                        <div class="bg-white p-2">
                            <!-- 汽機車貸款    -->
                            <div v-if="select_loan === 0">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款額度</p>
                                <p class="font-weight-bolder font-xl text-center">最高<span
                                    style="font-size: 1.5em; line-height: .8em">200</span>萬</p>
                                <p class="font-weight-bold text-center">依車種及信用狀況、還款能力等綜合評估</p>
                            </div>
                            <!-- 房屋貸款  -->
                            <div v-if="select_loan === 1">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款額度</p>
                                <p class="font-weight-bolder font-xxl text-center">房屋價值60%-80%</p>
                                <p class="font-weight-bold text-center">依「鑑價」結果決定房屋價值。</p>
                            </div>
                            <!-- 信用貸款    -->
                            <div v-if="select_loan === 2">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款額度</p>
                                <p class="font-weight-bolder font-xl text-center">最高<span
                                    style="font-size: 1.5em; line-height: .8em">200</span>萬</p>
                                <p class="font-weight-bold text-center">信貸額度將依銀行審核而定。</p>
                            </div>
                            <!-- 商品貸款  -->
                            <div v-if="select_loan === 3">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款額度</p>
                                <p class="font-weight-bolder font-xl text-center">依資金需求<br/>以小額借貸為主</p>
                            </div>
                            <!-- 企業貸款 -->
                            <div v-if="select_loan === 4">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">企業貸款流程</p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">1</span>
                                    案件申請資料準備
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">2</span>
                                    擔保品、不動產估價
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">3</span>
                                    契約對保
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">4</span>
                                    抵押設定
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">5</span>
                                    帳號撥款
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="mb-2 mb-lg-0">
                        <div class="bg-white p-2">
                            <!-- 汽機車貸款    -->
                            <div v-if="select_loan === 0">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">貸款額度</p>
                                <p class="row">
                                <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">一般機車</span>
                                    <img :src="require('../assets/icon/car/scooter.png')" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">重型機車</span>
                                    <img :src="require('../assets/icon/car/heavy_moto.png')" alt=""
                                         style="width: 100%"/>
                                </span>
                                    <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">一般汽車</span>
                                    <img :src="require('../assets/icon/car/car.png')" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">貨車</span>
                                    <img :src="require('../assets/icon/car/truck.png')" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">公司車</span>
                                    <img :src="require('../assets/icon/car/ex_car.png')" alt="" style="width: 100%"/>
                                </span>
                                    <span class="col-4 text-center my-1 d-flex flex-column justify-content-between">
                                    <span class="font-weight-bold " style="font-size: .85em">計程車</span>
                                    <img :src="require('../assets/icon/car/taxi.png')" alt="" style="width: 100%"/>
                                </span>
                                </p>
                            </div>
                            <!-- 房屋貸款  -->
                            <div v-if="select_loan === 1">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">還款方式</p>
                                <p class="font-weight-bolder font-xl text-center">A.本金攤還<br/>B.本利攤還</p>
                                <p class="font-weight-bold text-center">依房貸方案不同。</p>
                            </div>
                            <!-- 信用貸款    -->
                            <div v-if="select_loan === 2">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">還款方式</p>
                                <p class="font-weight-bolder font-xl text-center" style="line-height: .8em;">
                                    每月本金<br/>+<br/>利息平均分攤</p>
                                <p class="font-weight-bold text-center">依銀行方案不同。</p>
                            </div>
                            <!-- 商品貸款  -->
                            <div v-if="select_loan === 3">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">還款方式</p>
                                <p class="font-weight-bolder font-xl text-center">本金利息<br/>平均攤還</p>
                            </div>
                            <!-- 企業貸款 -->
                            <div v-if="select_loan === 4">
                                <p class="font-lg font-weight-bolder bg-sukudai-info text-center">文件準備</p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">1</span>
                                    雙證件
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">2</span>
                                    近三年資產負債表
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">3</span>
                                    營業登記（市政府函）
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">4</span>
                                    近一年甲存
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">5</span>
                                    近一年乙存
                                </p>
                                <p>
                                <span
                                    class="bg-sukudai-yellow rounded-circle text-center d-inline-flex justify-content-center align-items-center mx-2 font-lg font-weight-bolder"
                                    style="width: 2rem; height: 2rem;">6</span>
                                    近三年 401 報表
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 貸款流程 -->
        <div class="py-4">
            <div class="text-center">
                <h1>
                    <span class="px-5 font-weight-bolder"
                          style="background: linear-gradient(transparent 80%, var(--sukudai-title-info) 80%)">
                        貸款流程
                    </span>
                </h1>
                <b-container class="bv-example-row py-2">
                    <b-row class="justify-content-center">
                        <b-col cols="6" lg="3">
                            <img src="../assets/icon/process/process_1.png" alt="" style="width: 100%"/>
                            <p class="font-weight-bolder font-lg">專人洽談</p>
                        </b-col>

                        <b-col cols="6" lg="3">
                            <img src="../assets/icon/process/process_2.png" alt="" style="width: 100%"/>
                            <p class="font-weight-bolder font-lg">準備資料</p>
                        </b-col>

                        <b-col cols="6" lg="3">
                            <img src="../assets/icon/process/process_3.png" alt="" style="width: 100%"/>
                            <p class="font-weight-bolder font-lg">專業審核</p>
                        </b-col>

                        <b-col cols="6" lg="3">
                            <img src="../assets/icon/process/process_4.png" alt="" style="width: 100%"/>
                            <p class="font-weight-bolder font-lg">成功核貸</p>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>

        <!-- 為選擇速可貸 -->
        <div class="py-4 bg-sukudai-light-grey">
            <h1 class="text-center">
                <span class="px-5 font-weight-bolder"
                      style="background: linear-gradient(transparent 80%, var(--sukudai-title-info) 80%)">
                    為何選擇速可貸
                </span>
            </h1>
            <b-container class="bv-example-row text-center py-2">
                <b-row class="justify-content-center">
                    <b-col cols="6" lg="3">
                        <b-button class="w-100 border-0 bg-sukudai-green font-lg font-weight-bolder py-1">
                            手續簡單
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_1.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="w-100 border-0 bg-sukudai-yellow font-lg font-weight-bolder py-1">
                            輕鬆還款
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_2.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="w-100 border-0 bg-sukudai-green font-lg font-weight-bolder py-1">
                            高額度
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_3.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="w-100 border-0 bg-sukudai-yellow font-lg font-weight-bolder py-1">
                            專業服務
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_4.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <b-button variant="warning" size="lg"
                                  class="text-white font-lg font-weight-bolder py-1"
                                  @click="$bvModal.show(modalId)">
                            立即諮詢
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 成功核貸用戶心得 -->
        <comment/>

        <b-modal :id="modalId" hide-footer>
            <quick-submit/>
        </b-modal>
    </div>
</template>

<script>
import comment from "./comment";
import ImageCarousel from "./ImageCarousel";
import quickSubmit from "./quickSubmit";
import trackService from "@/services/trackService";

export default {
    components: {
        comment,
        ImageCarousel,
        quickSubmit
    },

    props: {
        loadType: Number,
    },

    data() {
        return {
            select_loan: 0,
            sliding: null,
            text: '',
            text2: "This is some text.\nIt is read only and doesn't look like an input.",
            modalId: 'contact-now-modal'
        }
    },

    mounted() {
        if (this.loadType) {
            this.select_loan = this.loadType % 5;
        }
    },

    methods: {
        changeLoanType(loanType) {
            this.select_loan =
                0 <= loanType <= 4 ? loanType : 0;

            let title = ['汽機車借貸', '房屋貸款', '信用貸款', '商品貸款', '企業貸款'][this.select_loan];
            setTimeout(() => {
                trackService({trackedTitle: `速可貸方案 - ${title}`} )
            }, 500);
        },
    }
}
</script>

<style lang="scss" scoped>
.loan-type-col {
    flex-grow: 0;
    flex-basis: 100%;

    @media (min-width: 576px) {
        flex-basis: 50%;
    }

    @media (min-width: 768px) {
        flex-basis: calc(100% / 3);
    }

    @media (min-width: 992px) {
        flex-basis: 20%;
    }
}
</style>